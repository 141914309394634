<template>
<div class="page_resume">
  <div class="inner" v-if="Object.keys(resume).length>0">
    <div class="resume_content">
      <div class="resume_person_inner">
        <div class="title">工作经历</div>
        <div class="resume_item" v-for="item in resume.userWorkExperienceList" :key="item.startDate">
          <div class="resume_item_top">
            <div class="item_date">{{item.startDate}}-{{item.endDate}}</div>
            <div class="item_title">{{item.companyName}}</div>
            <div class="item_brief">{{item.department}}‧{{item.post}}</div>
          </div>
          <div class="resume_item_bottom">
            {{item.content}}
          </div>
        </div>

        <div class="title">项目经历</div>
        <div class="resume_item" v-for="item in resume.userProjectExperienceList" :key="item.startDate">
          <div class="resume_item_top">
            <div class="item_date">{{item.startDate}}-{{item.endDate}}</div>
            <div class="item_title">{{item.projectName}}</div>
            <div class="item_brief">{{item.projectRole}}</div>
          </div>
          <div class="resume_item_bottom">
            {{item.content}}
          </div>
        </div>

        <div class="title">教育背景</div>
        <div class="resume_item" v-for="item in resume.userEducationBackgroundList" :key="item.startDate">
          <div class="resume_item_top">
            <div class="item_date">{{item.startDate}}-{{item.endDate}}</div>
            <div class="item_title">{{item.schoolName}} - <span class="item_brief" style="margin-right:0;">{{userStore.getXueZhi(item.xuezhi)}}</span></div>
            <div class="item_brief">{{item.specialty}}</div>
            <div class="item_brief">{{userStore.getDegree(item.education)}}</div>
          </div>
          <div class="resume_item_bottom">
            {{item.content}}
          </div>
        </div>

        <div class="title">资质证书</div>
        <div class="resume_item">
          <div class="cert_item" v-for="item in resume.userCertificateList" :key="item.imageUrl">《certificateName》<a :href="item.imageUrl" target="_blank">浏览</a></div>
        </div>
        <div class="title">兴趣爱好</div>
        <div class="resume_item">
          <div class="resume_item_bottom">
            {{resume.hobbies}}
          </div>
        </div>
      </div>
    </div>
    <div class="resume_person">
      <div class="resume_person_inner">
        <div class="photo">
          <img :src="resume.user.avatarUrl"/>
          <div class="name">{{resume.user.realName}}</div>
          <div class="status">{{userStore.getJobStatus(resume.user.workStatus)}}</div>
        </div>
        <div class="title">基本信息</div>
        <div class="info_item">
          <div>电话：{{resume.user.mobile}}</div>
          <div>邮箱：{{resume.user.email}}</div>
          <div>生日：{{resume.user.birthDate}}</div>
          <div>微信：{{resume.user.wechat}}</div>
          <div>住址：{{resume.user.provinceName}} {{resume.user.cityName}}</div>
        </div>
        <div class="title">应聘职位</div>
        <div class="info_item">
          <div>{{expectedJob.join("、")}}</div>
        </div>
        <div class="title">个人优势</div>
        <div class="info_item">
          <div style="text-align: justify;">{{resume.advantage}}</div>
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<script>
import {viewResume} from "@/api/company/company";
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
export default {
  name: "resume",
  data(){
    return {
      resume:{

      },
      expectedJob:[]
    }
  },
  mounted(){
    if(this.$route.query.id){
      viewResume({
        userApplyId:this.$route.query.id
      }).then(res => {
        if(res.code === 0){
          this.resume = res.data;
          if(this.resume.expectPostList){
            for(let i in this.resume.expectPostList){
              this.expectedJob.push(
                  this.resume.expectPostList[i].postClassificationName
              );
            }
          }
        }
      })
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style lang="less">
  .page_resume{
    padding-bottom:25px;
    .inner{
      display:flex;
    }
    .resume_content{
      flex:1;
      padding-top:15px;
      background-color:#fff;
      .resume_item{
        margin-top:25px;
        .cert_item{
          font-size:15px;
        }
        a{
          color:#00b0b1;
          text-decoration: none;
          margin-left:10px;
        }
      }
      .resume_item_bottom{
        margin-top:15px;
        font-size: 14px;
        color: #666666;
      }
      .resume_item_top{
        display:flex;
        color:#1a1a1a;
        align-items: flex-end;
        .item_date{
          font-size:16px;
          margin-right:40px;
        }
        .item_title{
          font-size:15px;
          font-weight:bold;
          margin-right:40px;
        }
        .item_brief{
          font-size:13px;
          margin-right:40px;
        }
      }
    }
    .title{
      font-size: 19px;
      margin-top:30px;
      color: #00b0b1;
    }
    .resume_person_inner{
      margin:30px;
    }
    .resume_person{
      width:376px;
      margin-left:10px;
      background-color:#fff;
      text-align: center;
      .name{
        font-size: 30px;
        color: #1a1a1a;
        font-weight: bold;
      }
      .status{
        font-size: 16px;
        color: #666666;
        margin-top:5px;
      }
      .photo{
        padding-top:50px;
      }
      .info_item{
        margin-top:15px;
        font-size: 15px;
        color: #666666;
      }
      img{
        width:80px;
        height:80px;
        border-radius: 80px;
      }
    }
  }
</style>
